/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import { useState } from 'react';

const useImageUpload = () => {
    const [ipfsCID, setipfsCID] = useState('');
    const [loading, setLoading] = useState(false);
    const [uploadError, setUploadError] = useState(null);

    const handleFileSelection = async (event) => {
        setLoading(true);
        const image = event.target.files[0];
        const formData = new FormData();
        formData.append('image', image);

        const response = await fetch('/upload-image', {
            method: 'POST',
            body: formData
        });

        if (!response.ok) {
            console.error('Image upload failed');
            const errorData = await response.json();
            setUploadError(errorData.error); 
            setLoading(false);
            return;
        }

        const data = await response.json();
        setipfsCID(data.cid);
        setUploadError(null);
        setLoading(false);
    };

    return {
        ipfsCID,
        handleFileSelection,
        loading,
        uploadError
    }
};

export default useImageUpload;
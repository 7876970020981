/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Header from './components/Header';
import HashConnectButton from './components/HashConnectButton';
import InterestDropdown from './components/InterestDropdown';
import ThreadIndex from './components/ThreadIndex';
import Thread from './components/Thread.js';
import NewThread from './components/NewThread';
import AboutPage from './components/AboutPage';
import HttpsRedirect from './HttpsRedirect';
import './App.css';

const queryClient = new QueryClient();


function App() {
  const { interestId } = useParams();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Header />
        <Router>
          <HttpsRedirect />
          <header>
            <InterestDropdown />
            <Link to="/" className="about-link">Home</Link>
          </header>
          <HashConnectButton />
          <Routes>
            <Route exact path="/:interestId" element={<ThreadIndex key={interestId} />} />
            <Route exact path="/:interestId/thread/:id" element={<Thread />} />
            <Route exact path="/:interestId/new-thread" element={<NewThread />} />
            <Route path="/" element={<AboutPage />} />
          </Routes>
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;

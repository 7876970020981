/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createTopicTransaction, createOPTransaction, sendTransaction, executeMicropayment } from '../services/hedera';
import { TransactionReceipt } from '@hashgraph/sdk';
import { useHashConnect } from '../contexts/HashConnectContext';
import useInputWithWordLimit from '../hooks/useInputWithWordLimit';
import useImageUpload from '../hooks/useImageUpload';
import './NewThread.css';
import { interests } from '../assets/interests';
const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const NewThread = () => {
    const { interestId } = useParams();
    const { accountId, initData, hashconnect } = useHashConnect();
    const [subject, setSubject] = useState('');
    const navigate = useNavigate();
    const [subjectError, setSubjectError] = useState('');
    const [selectedInterest, setSelectedInterest] = useState('');

    const checkSubjectLength = (subject) => {
        const maxLength = 75;
        if (subject.length <= maxLength) {
            setSubjectError('');
            return true;
        } else {
            setSubjectError(`Subject must be under ${maxLength} characters.`);
            return false;
        }
    };

    const handleSubjectChange = (e) => {
        setSubject(e.target.value);
        checkSubjectLength(e.target.value);
    };

    const { value: comment, error: commentError, setValue: setComment, validate: validateComment } = useInputWithWordLimit('', 777);
    const { ipfsCID, handleFileSelection, loading, uploadError } = useImageUpload();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!checkSubjectLength(subject) || !validateComment() || (interestId === 'all' && !selectedInterest)) {
            return;
        }
        const isMicropaymentSuccessful = await executeMicropayment(
            hashconnect,
            initData,
            accountId,
            '0.0.1426500',
            10_000_000
        );

        if (!isMicropaymentSuccessful) {
            return;
        }

        const createTopic = createTopicTransaction(accountId, subject);
        const createTopicResponse = await sendTransaction(
            hashconnect,
            initData,
            createTopic,
            accountId
        );

        const opData = {
            subject,
            threadId: TransactionReceipt.fromBytes((createTopicResponse.receipt)).topicId.toString(),
            comment,
            image: ipfsCID
        }
        const createOP = createOPTransaction(accountId, opData);

        const { success } = await sendTransaction(
            hashconnect,
            initData,
            createOP,
            accountId
        );

        try {
            const response = await fetch('/create-thread', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    subject,
                    threadId: opData.threadId,
                    userId: accountId,
                    comment,
                    image: ipfsCID,
                    interestId: selectedInterest || interestId,
                    success
                }),
            });

            if (response.status === 201) {
                const data = await response.json();
                navigate(`/${interestId}/thread/${data.threadId}`);
                event.target.reset();
                setSubject('');
                setComment('');
            } else {
                console.error('Failed to create thread');
            }
        } catch (error) {
            console.error('Error creating thread:', error);
        }
    };

    return (
        <div className="NewThread">
            <button className="BackButton" onClick={() => navigate(-1)}>Back</button>
            <h2>Create New Thread</h2>
            <form onSubmit={handleSubmit}>
                {
                    interestId === 'all' && (
                        <>
                            <label htmlFor="interest">Interest:</label>
                            <select
                                id="interest"
                                className="newthread-dropdown"
                                value={selectedInterest}
                                onChange={(e) => setSelectedInterest(e.target.value)}
                                required
                            >
                                <option value="">Select interest board</option>
                                {interests.filter(interest => interest.path !== '/all').map((interest) =>
                                    <option key={interest.path} value={interest.path.slice(1)}>
                                        {interest.path}/ - {interest.name}
                                    </option>
                                )}
                            </select>
                        </>
                    )
                }
                <label htmlFor="subject">Subject:</label>
                <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={handleSubjectChange}
                    required
                />
                {subjectError && <p className="subject-error">{subjectError}</p>}
                <label htmlFor="comment">Comment:</label>
                <textarea
                    id="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                {commentError && <p className="error">{commentError}</p>}
                <input
                    type="file"
                    id="image"
                    name="image"
                    accept="image/*"
                    onChange={handleFileSelection}
                    required
                />
                {uploadError && <p className="error">{uploadError}</p>}
                <button type="submit" disabled={loading || uploadError}>Submit</button>
            </form>
        </div>
    );
};

export default NewThread;


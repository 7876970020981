/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import React from "react";
import { useNavigate } from "react-router-dom";
import { interests } from '../assets/interests';

const InterestDropdown = () => {
    const navigate = useNavigate();
    

    const getInterestName = (interestId) => {
        const interest = interests.find((i) => i.path === `/${interestId}`);
        return interest ? interest.name : '';
    };

    const handleChange = (e) => {
        const interestPath = e.target.value;
        const interestId = interestPath.slice(1);
        if (interestPath) {
            const stateObject = { interestName: getInterestName(interestId) };
            if(interestId === 'all') {
                sessionStorage.setItem('fromAllPage', 'true');
            } else {
                sessionStorage.removeItem('fromAllPage');
            }
            navigate(interestPath, { state: stateObject });
        }
    };

    return (
        <div className="header-dropdown-container">
            <select className="header-dropdown" onChange={handleChange}>
                <option value="">Select an interest</option>
                {interests.map((interest, index) => (
                    <option key={index} value={interest.path}>
                        {interest.path}/ - {interest.name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default InterestDropdown;

/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

export const interests = [
    { name: "All", path: "/all" },
    { name: "Advice", path: "/advice" },
    { name: "Anime", path: "/anime" },
    { name: "Arts & Culture", path: "/arts" },
    { name: "Books & Literature", path: "/books" },
    { name: "Business & Finance", path: "/biz" },
    { name: "Food & Cooking", path: "/cook" },
    { name: "Do It Yourself", path: "/diy" },
    { name: "Fitness", path: "/fit" },
    { name: "Video Games", path: "/games" },
    { name: "Gardening", path: "/garden" },
    { name: "General", path: "/general" },
    { name: "Hedera", path: "/hedera" },
    { name: "Music", path: "/music" },
    { name: "Outdoors", path: "/out" },
    { name: "Pets & Wildlife", path: "/pets" },
    { name: "Photography", path: "/photo" },
    { name: "Politics", path: "/pol" },
    { name: "Paranormal", path: "/x" },
    { name: "Science & Math", path: "/sci" },
    { name: "Sports", path: "/sports" },
    { name: "Technology", path: "/tech" },
    { name: "Television & Movies", path: "/tv" },
    { name: "Travel & Adventure", path: "/travel" },
];
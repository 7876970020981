/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

export const generateHashScanUrl = (userId, postId, network = 'mainnet') => {
    return `https://hashscan.io/${network}/transaction/${userId}@${postId}`;
};

export const convertTimestampToDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleString();
}

export const scrollToElement = (elementId, behavior = 'smooth') => {
    document.getElementById(elementId).scrollIntoView({ behavior });
};

export const filterAndMapVotes = (messages) => {
    const votes = messages.filter((message) => message.type === 'vote');

    const upvotes = {};
    const downvotes = {};
    const donations = {};

    votes.forEach((vote) => {
        if (vote.voteType === 'upvote') {
            if (!upvotes[vote.postId]) upvotes[vote.postId] = new Set();
            upvotes[vote.postId].add(vote.voterId);
        } else if (vote.voteType === 'downvote') {
            if (!downvotes[vote.postId]) downvotes[vote.postId] = new Set();
            downvotes[vote.postId].add(vote.voterId);
        }

        if (vote.donationAmount) {
            if (!donations[vote.postId]) donations[vote.postId] = 0;
            donations[vote.postId] += vote.donationAmount;
        }
    });

    return { upvotes, downvotes, donations };
};

export const filterAndMapThreadPosts = (messages, votes) => {
    return messages
        .filter((message) => message.type !== 'vote')
        .map((post) => {
            const { postId } = post;
            const upvotes = votes.upvotes[postId]?.size || 0;
            const downvotes = votes.downvotes[postId]?.size || 0;
            const totalHbar = (votes.donations[postId] || 0) / 100_000_000;
            return { ...post, upvotes, downvotes, totalHbar };
        });
};

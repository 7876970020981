/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import { TopicMessageSubmitTransaction, TransactionId, AccountId, TopicCreateTransaction, TransferTransaction, Hbar } from '@hashgraph/sdk';

export const createMicropaymentTransaction = (fromAccountId, toAccountId, amount) => {
    const transId = TransactionId.generate(fromAccountId);
    return new TransferTransaction()
        .setTransactionId(transId)
        .setNodeAccountIds([new AccountId(3)])
        .addHbarTransfer(fromAccountId, Hbar.fromTinybars(-amount))
        .addHbarTransfer(toAccountId, Hbar.fromTinybars(amount))
        .freeze();
};

export const executeMicropayment = async (hashconnect, initData, accountId, toAccountId, amount, postCreatorId, postTxId) => {
    const micropaymentTransaction = postCreatorId
        ? upvoteMicropaymentTransaction(accountId, toAccountId, postCreatorId, amount, postTxId)
        : createMicropaymentTransaction(accountId, toAccountId, amount);

    const micropaymentResponse = await sendTransaction(hashconnect, initData, micropaymentTransaction, accountId);

    if (!micropaymentResponse.success) {
        console.error('Micropayment failed, post not created');
        return false;
    }

    return true;
};


export const createTopicTransaction = (accountId, subject) => {
    const transId = TransactionId.generate(accountId);
    return new TopicCreateTransaction()
        .setTransactionId(transId)
        .setNodeAccountIds([new AccountId(3)])
        .setTopicMemo(subject)
        .setMaxTransactionFee(Hbar.fromTinybars(50_000_000))
        .freeze();
};

export const createOPTransaction = (accountId, op) => {
    const transId = TransactionId.generate(accountId);
    const message = {
        type: 'original-post',
        subject: op.subject,
        comment: op.comment,
        userId: accountId,
        postId: transId.toString().split('@')[1],
        image: op.image
    };
    return new TopicMessageSubmitTransaction()
        .setTransactionId(transId)
        .setNodeAccountIds([new AccountId(3)])
        .setTopicId(op.threadId)
        .setMessage(JSON.stringify(message))
        .setMaxTransactionFee(Hbar.fromTinybars(5_000_000))
        .freeze();
};

export const createTopicReplyTransaction = (
    id,
    comment,
    userId,
    image,
    accountId
) => {
    const transId = TransactionId.generate(accountId);
    const message = {
        type: 'reply',
        threadId: id,
        comment,
        userId,
        postId: transId.toString().split('@')[1],
        image
    };

    return new TopicMessageSubmitTransaction()
        .setTransactionId(transId)
        .setNodeAccountIds([new AccountId(3)])
        .setTopicId(id)
        .setMessage(JSON.stringify(message))
        .setMaxTransactionFee(Hbar.fromTinybars(5_000_000))
        .freeze();
};

export const createVoteTransaction = (threadId, post, voteType, voterId, donationAmount) => {
    const transId = TransactionId.generate(voterId);
    const message = {
        type: 'vote',
        postId: post.postId,
        userId: post.userId,
        voteType,
        voterId,
    };

    if (voteType === 'upvote') {
        message.donationAmount = donationAmount;
    }

    return new TopicMessageSubmitTransaction()
        .setTransactionId(transId)
        .setTopicId(threadId)
        .setMessage(JSON.stringify(message))
        .setNodeAccountIds([new AccountId(3)])
        .setMaxTransactionFee(Hbar.fromTinybars(5_000_000))
        .freeze();
};

export const upvoteMicropaymentTransaction = (fromAccountId, appCreatorAccountId, postCreatorAccountId, amount, postTxId) => {
    const transId = TransactionId.generate(fromAccountId);
    amount = Math.round(amount);
    const creatorAmount = Math.floor(amount * 0.7);
    const platformAmount = amount - creatorAmount;

    return new TransferTransaction()
        .setTransactionId(transId)
        .setNodeAccountIds([new AccountId(3)])
        .addHbarTransfer(fromAccountId, Hbar.fromTinybars(-amount))
        .addHbarTransfer(postCreatorAccountId, Hbar.fromTinybars(creatorAmount))
        .addHbarTransfer(appCreatorAccountId, Hbar.fromTinybars(platformAmount))
        .setMaxTransactionFee(Hbar.fromTinybars(5_000_000))
        .setTransactionMemo(`4orum.io Free Speech Set Free - upvote donation for post id: ${postTxId}`)
        .freeze();
};

export const sendTransaction = async (hashconnect, initData, transaction, accountId) => {
    const response = await hashconnect.sendTransaction(
        initData.topic,
        {
            topic: initData.topic,
            byteArray: transaction.toBytes(),
            metadata: {
                accountToSign: accountId,
                returnTransaction: false,
            },
        }
    );
    return response;
};

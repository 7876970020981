/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import React, { useState, useEffect, useCallback } from 'react';
import { HashConnect } from 'hashconnect';
import { useHashConnect } from '../contexts/HashConnectContext';

const HashConnectButton = () => {
  const [connected, setConnected] = useState(false);

  const { setInitData, setProvider, setSigner, accountId, setAccountId, setHashconnect, hashconnect, initData } = useHashConnect();

  const initializeHashConnect = useCallback(async () => {
    const appMetadata = {
      name: "4orum",
      description: "Welcome to 4orum, a Web3 forum built with Hedera, championing free speech and open communication. Free Speech Set Free - 4orum",
      url: "https://fo4rm.herokuapp.com",
    };

    const hc = new HashConnect();
    setHashconnect(hc);

    hc.pairingEvent.once((pairingData) => {
      setConnected(true);
      setAccountId(pairingData.accountIds[0]);

      const provider = hc.getProvider("mainnet", initData.topic, pairingData.accountIds[0]);
      const signer = hc.getSigner(provider);

      setProvider(provider);
      setSigner(signer);

      localStorage.setItem('connected', true);
      localStorage.setItem('accountId', pairingData.accountIds[0]);
    });

    const initData = await hc.init(appMetadata, "mainnet", false);
    setInitData(initData);
  }, [setInitData, setProvider, setSigner, setAccountId, setHashconnect]);

  const connectToLocalWallet = () => {
    if (hashconnect && !connected) {
      hashconnect.connectToLocalWallet();
    }
  };

  const disconnect = () => {
    if (hashconnect && connected) {
      hashconnect.disconnect(initData.topic)
      setConnected(false);
      setAccountId(null);
      localStorage.removeItem('connected');
      localStorage.removeItem('accountId');
    }
  };

  useEffect(() => {
    const connectedFromStorage = localStorage.getItem('connected');
    const accountIdFromStorage = localStorage.getItem('accountId');

    if (connectedFromStorage && accountIdFromStorage) {
      setConnected(true);
      setAccountId(accountIdFromStorage);
    }

    initializeHashConnect();
  }, [initializeHashConnect, setAccountId]);

  return (
    <div className={`hashconnect-button${connected ? ' connected' : ''}`}>
      {connected ? (
        <>
          <span className="connected-dot"></span>
          <span className="connected-account-id">{accountId}</span>
          <button className="disconnect-button" onClick={disconnect}>⏻</button>
        </>
      ) : (
        <button className="connect-button" onClick={connectToLocalWallet}>Connect</button>
      )}
    </div>
  );
};

export default HashConnectButton;

/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import { useState } from 'react';

const useInputWithWordLimit = (initialValue, maxWords) => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState('');

    const handleValueChange = (newValue) => {
        setValue(newValue);
        checkValueLength(newValue);
    };

    const checkValueLength = (value) => {
        const wordCount = value.split(/\s+/).length;
        if (wordCount <= maxWords) {
            setError('');
            return true;
        } else {
            setError(`Input must be under ${maxWords} words.`);
            return false;
        }
    };

    return { value, error, setValue: handleValueChange, validate: () => checkValueLength(value) };
};

export default useInputWithWordLimit;

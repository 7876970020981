/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import { createContext, useContext, useState } from 'react';

const HashConnectContext = createContext(null);

export const useHashConnect = () => {
  return useContext(HashConnectContext);
};

export const HashConnectProvider = ({ children }) => {
  const [initData, setInitData] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [hashconnect, setHashconnect] = useState(null);



  const value = {
    initData,
    setInitData,
    provider,
    setProvider,
    signer,
    setSigner,
    accountId,
    setAccountId,
    hashconnect,
    setHashconnect
  };

  return (
    <HashConnectContext.Provider value={value}>
      {children}
    </HashConnectContext.Provider>
  );
};

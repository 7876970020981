/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import React, { useState } from 'react';

function YouTubeEmbed({ link }) {
    const [embed, setEmbed] = useState(false);

    const videoURL = new URL(link);
    let youtubeID;

    if (videoURL.hostname === 'youtu.be') {
        youtubeID = videoURL.pathname.slice(1).split('%20')[0];
    } else {
        youtubeID = videoURL.searchParams.get('v').split('%20')[0];
    }

    const start = videoURL.searchParams.get('t');

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const handleEmbedClick = (e) => {
        if(!isMobile){
            e.preventDefault();
            setEmbed(!embed);
        }
    }

    console.log("YouTube Video ID: ", youtubeID);

    return (
        <div>
            {isMobile ? <a href={link}>{link}</a> : link}
            <a href={isMobile ? `https://www.youtube.com/watch?v=${youtubeID}${start ? `&t=${start}` : ''}` : '#'} onClick={handleEmbedClick}>[{embed ? 'Remove' : (isMobile ? 'Open' : 'Embed')}]</a>
            {!isMobile && embed && 
                <iframe 
                    width="560" 
                    height="315" 
                    src={`https://www.youtube.com/embed/${youtubeID}${start ? `?start=${start}` : ''}`}
                    title="YouTube video player" 
                    style={{border: "0"}}  
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen
                ></iframe>
            }
        </div>
    )
}

export default YouTubeEmbed;

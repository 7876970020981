/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AboutPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const tableStyles = {
        borderCollapse: 'collapse',
        width: '100%',
        textAlign: 'left'
    };

    const thStyles = {
        borderBottom: '1px solid #ddd',
        padding: '8px',
        textAlign: 'center'
    };

    const tdStyles = {
        borderBottom: '1px solid #ddd',
        padding: '8px',
        textAlign: 'center'
    };

    const hasHistory = location?.state?.from;

    return (
        <div>
            <div className="main-content">
                {hasHistory && (
                    <button onClick={() => navigate(-1)} style={{ marginBottom: '1rem' }}>
                        Back
                    </button>
                )}
                <h1 style={{ textAlign: 'center', paddingTop: '75px' }}>Welcome to 4orum: Free Speech Set Free</h1>
                <p>
                    As free speech faces growing challenges, our Web3 forum offers a cutting-edge solution. Built on Hedera, our platform aims to preserve and foster open communication for all.
                </p>
                <p>
                    By harnessing the power of the Hedera Consensus Service, we provide a space where users can express themselves without censorship. Here, voices around the world can come together and be heard to create a rich, intellectual ecosystem through open and honest debate.
                </p>
                <p>
                    To participate in 4orum, <strong>you must have a Hashpack wallet with enough HBAR to cover the cost of 4orum actions.</strong> These actions are posting threads, replies, or votes. Our platform utilizes unique Web3 elements that enhance the user experience:
                </p>
                <ul>
                    <li>
                        <strong>Everything onchain:</strong> Threads, replies, and votes are all managed on-chain, with each thread acting as a topic and replies as messages within that topic. Votes are also messages on the topic but they are not shown on the screen.
                    </li>
                    <li>
                        <strong>IPFS storage:</strong> Users can choose to upload an image when posting, which is then uploaded to IPFS storage and written into the message on-chain.
                    </li>
                    <li>
                        <strong>Contributors rewarded:</strong> Users who receive upvotes will earn 70% of the HBAR donation specified by the user sending the upvote (minimum donation: 0.1 HBAR), with the remaining 30% supporting the site owner in maintaining and enhancing the forum experience.
                    </li>
                </ul>
                <h3>4orum Action Fees</h3>
                <table style={tableStyles}>
                    <thead>
                        <tr>
                            <th style={thStyles}>Action</th>
                            <th style={thStyles}>Network Fee (USD)</th>
                            <th style={thStyles}>Micropayment (HBAR)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={tdStyles}>Create Thread</td>
                            <td style={tdStyles}>$0.0101</td>
                            <td style={tdStyles}>0.1</td>
                        </tr>
                        <tr>
                            <td style={tdStyles}>Reply or Downvote</td>
                            <td style={tdStyles}>$0.0001</td>
                            <td style={tdStyles}>0.1</td>
                        </tr>
                        <tr>
                            <td style={tdStyles}>Upvote</td>
                            <td style={tdStyles}>$0.0001</td>
                            <td style={tdStyles}>HBAR donation amount</td>
                        </tr>
                    </tbody>
                </table>

                <p>
                    While all content is stored on-chain, 4orum's index is powered by MongoDB. If a thread contains illegal content as per United States law or overly distasteful, it will be removed from the index. The thread will still be accessible via a direct link but will not appear on the thread index page, ensuring a lawful and pleasantly curated environment.
                </p>
                <p>
                    At 4orum, we prioritize user experience and privacy. That's why our platform is free from captchas and annoying ads. Enjoy seamless browsing and participation in discussions without interruptions or distractions, while maintaining your privacy.
                </p>
                <h3>How to Post on Mobile</h3>
                <ol>
                    <li>Download the Hashpack app from <a href="https://www.hashpack.app/download" target="_blank" rel="noopener noreferrer">here</a>.</li>
                    <li>Open the Hashpack app and go to the Dapp tab.</li>
                    <li>Add 4orum.io to the Dapp tab.</li>
                    <li>Pair your wallet with 4orum by approving the connection.</li>
                    <li>Once your wallet is paired, you can create threads, reply, and vote within the Hashpack wallet under the Dapp tab.</li>
                </ol>
                <h3>How to Post on Non-Mobile Devices</h3>
                <ol>
                    <li>Install the <a href="https://chrome.google.com/webstore/detail/hashpack/gjagmgiddbbciopjhllkdnddhcglnemk" target="_blank" rel="noopener noreferrer">Hashpack browser extension.</a></li>
                    <li>Visit 4orum.io on your non-mobile device.</li>
                    <li>Click the "Connect" button at the top right of the screen to pair your wallet with the site.</li>
                    <li>Once your wallet is paired, you can create threads, reply, and vote on the platform.</li>
                </ol>
                <p>
                    We're excited to have you join 4orum in championing free speech. We set free speech free through open and honest debate together. May the repeating digits be with you.
                </p>
            </div>
        </div>
    );
};

export default AboutPage;
/*
 * Copyright 2023 4orum
 * 
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * 
 *     http://www.apache.org/licenses/LICENSE-2.0
 * 
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * 
*/

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";
import { useQuery } from 'react-query';
import Draggable from 'react-draggable';
import YouTubeEmbed from './YouTubeEmbed';
import { useHashConnect } from '../contexts/HashConnectContext';
import useInputWithWordLimit from '../hooks/useInputWithWordLimit';
import { useParams, Link } from 'react-router-dom';
import { generateHashScanUrl, convertTimestampToDate, scrollToElement, filterAndMapVotes, filterAndMapThreadPosts } from '../services/utils';
import { createTopicReplyTransaction, createVoteTransaction, executeMicropayment, sendTransaction } from '../services/hedera';
import LoadingScreen from './LoadingScreen';
import useImageUpload from '../hooks/useImageUpload';
import { interests } from '../assets/interests';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';

const Thread = () => {
    const { accountId, initData, hashconnect } = useHashConnect();
    const { interestId, id } = useParams();

    const [tempUserVotes, setTempUserVotes] = useState({});
    const [highlightedUserId, setHighlightedUserId] = useState(null);
    const [expandedImage, setExpandedImage] = useState(null);

    const [errorMsgVisible, setErrorMsgVisible] = useState({});
    const [errorMsg, setErrorMsg] = useState({});
    const errorMsgTimeoutRef = useRef({});
    const fromAllPage = sessionStorage.getItem('fromAllPage') === 'true';

    const handleWalletError = (postId, errorMessage) => {
        setErrorMsg((prev) => ({ ...prev, [postId]: errorMessage }));

        if (errorMsgTimeoutRef.current[postId]) {
            clearTimeout(errorMsgTimeoutRef.current[postId]);
        }

        errorMsgTimeoutRef.current[postId] = setTimeout(() => {
            setErrorMsg((prev) => {
                const { [postId]: _, ...rest } = prev;
                return rest;
            });
        }, 2000);
    };

    useEffect(() => {
        return () => {
            Object.values(errorMsgTimeoutRef.current).forEach(clearTimeout);
        };
    }, []);

    const [amountInputVisible, setAmountInputVisible] = useState({});
    const [amountToSend, setAmountToSend] = useState({});

    const getInterestName = (interestId) => {
        const interest = interests.find((i) => i.path === `/${interestId}`);
        return interest ? interest.name : '';
    };

    const interestName = getInterestName(interestId);

    const fetchThread = useCallback(async () => {
        try {
            const res = await fetch(`/get-thread/${id}`);
            const data = await res.json();
            const messages = data.messages.map((message) => JSON.parse(message));
            const votes = filterAndMapVotes(messages);
            const threadPosts = filterAndMapThreadPosts(messages, votes);
            const threadSubject = messages[0].subject;
            const userVotesInThread = messages
                .filter((msg) => msg.type === "vote" && msg.voterId === accountId)
                .reduce((votesObj, voteMsg) => {
                    votesObj[voteMsg.postId] = voteMsg.voteType;
                    return votesObj;
                }, {});

            return {
                subject: threadSubject,
                posts: threadPosts,
                existingUserVotes: userVotesInThread,
            };
        } catch (error) {
            console.error('Failed to fetch thread', error);
        }
    }, [id, accountId]);

    const {
        data: threadData,
        isLoading,
        isError,
        error,
        refetch,
    } = useQuery(['thread', id, accountId], fetchThread, {
        staleTime: 1000 * 60 * 5,
    });

    const subject = threadData?.subject || '';
    const posts = threadData?.posts || [];
    const existingUserVotes = threadData?.existingUserVotes || {};


    const { ipfsCID, handleFileSelection, loading, uploadError } = useImageUpload();
    const { value: floatingReply, error: floatingReplyError, setValue: setFloatingReply, validate: validateComment } = useInputWithWordLimit('', 777);
    const [showFloatingReplyForm, setShowFloatingReplyForm] = useState(false);

    const insertReplyId = (replyId) => {
        const cursorPosition = document.getElementById("floating-reply").selectionStart;
        const beforeCursor = floatingReply.substring(0, cursorPosition);
        const afterCursor = floatingReply.substring(cursorPosition);

        if (!floatingReply.includes(replyId)) {
            setFloatingReply(`${beforeCursor}>>${replyId}${replyId === posts[0].postId ? "(OP)#" : "#"}${afterCursor}\n`);
        }
    };

    const handleReplyButtonClick = (postId) => {
        if (!accountId) {
            setErrorMsgVisible({ ...errorMsgVisible, [postId]: true });
            handleWalletError(postId, 'You must connect your hashpack wallet to reply');
            return;
        }
        if (!showFloatingReplyForm) {
            setShowFloatingReplyForm(true);
        }
        insertReplyId(postId);
    };

    const submitFloatingReply = async (event) => {
        event.preventDefault();
        if (!validateComment()) {
            return;
        }
        const comment = floatingReply;
        const isMicropaymentSuccessful = await executeMicropayment(
            hashconnect,
            initData,
            accountId,
            '0.0.1426500',
            10_000_000
        );

        if (!isMicropaymentSuccessful) {
            return;
        }

        const replyTransaction = createTopicReplyTransaction(
            id,
            comment,
            accountId,
            ipfsCID,
            accountId
        );
        const { success } = await sendTransaction(hashconnect, initData, replyTransaction, accountId);

        try {
            await fetch('/create-reply', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    threadId: id,
                    success
                })
            });
            event.target.reset();
            fetchThread();
            setFloatingReply("");
            setShowFloatingReplyForm(false);
        } catch (error) {
            console.error("Failed to post reply", error);
        }
    };

    const handleUpvoteClick = (postId) => {
        if (!accountId) {
            setErrorMsgVisible({ ...errorMsgVisible, [postId]: true });
            handleWalletError(postId, 'You must connect your hashpack wallet to vote');
            return;
        }
        setAmountInputVisible((prevState) => ({
            ...prevState,
            [postId]: !prevState[postId],
        }));
    };

    const handleSendUpvote = (threadId, post) => {
        const amount = parseFloat(amountToSend[post.postId]) || 0;
        const tinybars = amount * 100_000_000;
        submitVote(threadId, post, 'upvote', tinybars);
        setAmountToSend({ ...amountToSend, [post.postId]: 0 });
        setAmountInputVisible({ ...amountInputVisible, [post.postId]: false });
    };

    const submitVote = async (threadId, post, voteType, amount) => {
        if (!accountId) {
            setErrorMsgVisible({ ...errorMsgVisible, [post.postId]: true });
            handleWalletError(post.postId, 'You must connect your hashpack wallet to vote');
            return;
        }
        if (post.userId === accountId) {
            setErrorMsgVisible({ ...errorMsgVisible, [post.postId]: true });
            setErrorMsg({ ...errorMsg, [post.postId]: "You cannot vote on your own post" });
        } else if (existingUserVotes[post.postId]) {
            setErrorMsgVisible({ ...errorMsgVisible, [post.postId]: true });
            setErrorMsg({
                ...errorMsg,
                [post.postId]: "You have already voted on this post",
            });
        } else {
            const isUpvote = voteType === 'upvote';
            if (isUpvote && amount < 10_000_000) {
                setErrorMsgVisible({ ...errorMsgVisible, [post.postId]: true });
                setErrorMsg({
                    ...errorMsg,
                    [post.postId]: "Minimum donation for upvote is 0.1 HBAR",
                });
                setTimeout(() => {
                    setErrorMsgVisible({ ...errorMsgVisible, [post.postId]: false });
                }, 2000);
                return;
            }

            setTempUserVotes({ ...tempUserVotes, [post.postId]: voteType });
            const isMicropaymentSuccessful = await executeMicropayment(
                hashconnect,
                initData,
                accountId,
                '0.0.1426500',
                isUpvote ? amount : 10_000_000,
                isUpvote ? post.userId : undefined,
                isUpvote ? `${post.userId}@${post.postId}` : undefined
            );
            if (!isMicropaymentSuccessful) {
                return;
            }
            const transaction = createVoteTransaction(threadId, post, voteType, accountId, amount);
            const signedTransaction = await sendTransaction(hashconnect, initData, transaction, accountId);
            try {
                if (signedTransaction.success) {
                    console.log('Vote submitted!');
                    setTempUserVotes({ ...tempUserVotes, [post.postId]: voteType });
                    setErrorMsg({ ...errorMsg, [post.postId]: '' });
                    fetchThread();
                }
            } catch (error) {
                console.error('Failed to submit vote', error);
            }
        }
        setTimeout(() => {
            setErrorMsgVisible({ ...errorMsgVisible, [post.postId]: false });
        }, 2000);
    };

    const getRepliesForPost = (postId) => {
        const parentIdRegex = />>(\d+(\.\d+)?)(\((?:OP)?\))?#/g;

        return posts.filter((post) => {
            if (!post.comment) return false;
            const matches = post.comment.matchAll(parentIdRegex);
            for (const match of matches) {
                if (match[1] === postId) {
                    return true;
                }
            }
            return false;
        });
    };

    const focusOnParent = (parentId) => {
        const parentElement = document.getElementById(parentId);

        if (parentElement) {
            parentElement.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            console.error(`Parent element with id ${parentId} not found.`);
        }
    };

    const handleParentIdClick = (e) => {
        if (e.target.classList.contains('parent-link')) {
            e.preventDefault();
            const parentId = e.target.dataset.parentId;
            focusOnParent(parentId);
        }
    };

    const handleUserIdClick = (userId) => {
        if (highlightedUserId === userId) {
            setHighlightedUserId(null);
        } else {
            setHighlightedUserId(userId);
        }
    };

    const getPostClassName = (userId) => {
        return userId === highlightedUserId ? 'Post HighlightedPost' : 'Post';
    };

    const handleExitFloatingReply = () => {
        setShowFloatingReplyForm(false);
        setFloatingReply('');
    };

    const renderFloatingReplyForm = () => (
        <Draggable cancel="textarea, input[type='file'], .exit-button, .submit-button">
            <form
                onSubmit={submitFloatingReply}
                className={`floating-reply-form ${showFloatingReplyForm ? 'display-block' : 'display-none'}`}
            >
                <button
                    type="button"
                    onClick={handleExitFloatingReply}
                    className="exit-button"
                >
                    &times;
                </button>
                <textarea
                    id="floating-reply"
                    value={floatingReply}
                    onChange={(e) => setFloatingReply(e.target.value)}
                    className="floating-reply-textarea"
                    rows="6"
                    cols="40"
                />
                {floatingReplyError && <p className="error">{floatingReplyError}</p>}
                <input
                    type="file"
                    name="floatingImage"
                    accept="image/*"
                    onChange={handleFileSelection}
                    className="floating-image-input"
                />
                {uploadError && <p className="error">{uploadError}</p>}
                <button type="submit" className="submit-button" disabled={loading || uploadError}>
                    Post Reply
                </button>
            </form>
        </Draggable>
    );

    const renderPost = (post, index) => {
        const lines = post.comment.split('\n');
        const parentIdRegex = />>(\d+(\.\d+)?)(\((?:OP)?\))?#/g;

        const isYoutubeLink = (urlString) => {
            let url;
            try {
                url = new URL(urlString);
            } catch (_) {
                return false;
            }

            const youtubeVideoIdRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
            const match = url.href.match(youtubeVideoIdRegex);

            return !!match && (url.hostname === 'www.youtube.com' || url.hostname === 'youtu.be' || url.hostname === 'm.youtube.com');
        };
        const parsedContent = lines.flatMap((line, i) => {
            const parts = [];
            let match;
            let lastIndex = 0;

            // Reset lastIndex before using the regex
            parentIdRegex.lastIndex = 0;

            // Extracting all the matching parts in the current line
            while ((match = parentIdRegex.exec(line)) !== null) {
                parts.push(
                    <a
                        key={`${i}-${match.index}`}
                        href={`#${match[1]}`}
                        className="parent-link"
                        data-parent-id={match[1]}
                        onClick={handleParentIdClick}
                    >
                        {line.substring(match.index, match.index + match[0].length)}
                    </a>
                );
                lastIndex = match.index + match[0].length;
            }

            // Handle the remaining part of the line after the last parentId
            const remaining = line.slice(lastIndex).trim();
            if (remaining) {
                if (remaining.startsWith('>') && !remaining.startsWith('>>')) {
                    parts.push(<span key={`${i}-${lastIndex}`} className="whitetext">{remaining}</span>);
                } else if (isYoutubeLink(remaining)) {
                    parts.push(<YouTubeEmbed key={`${i}-${lastIndex}`} link={remaining} />);
                } else {
                    parts.push(<span key={`${i}-${lastIndex}`}>{remaining}</span>);
                }
            }

            // If no parts were pushed, it means the line doesn't contain any post IDs
            if (parts.length === 0) {
                if (line.startsWith('>') && !line.startsWith('>>')) {
                    parts.push(<span key={i} className="whitetext">{line}</span>);
                } else if (isYoutubeLink(line)) {
                    parts.push(<YouTubeEmbed key={i} link={line} />);
                } else {
                    parts.push(<span key={i}>{line}</span>);
                }
            }

            return parts;
        });

        return (
            <>
                <div className="post-content">
                    <div className="post-text">
                        <div>
                            <strong>User:</strong>{" "}
                            <span className="UserId" onClick={() => handleUserIdClick(post.userId)}>
                                {post.userId}
                            </span>
                        </div>
                        <div>
                            <strong>Post ID:</strong>{" "}
                            <a href={generateHashScanUrl(post.userId, post.postId)} rel="noopener noreferrer">
                                {post.postId}
                            </a>
                            {" "}{convertTimestampToDate(post.postId)}
                        </div>
                        {index === 0 && (
                            <div>
                                <strong>Subject:</strong> {subject}
                            </div>
                        )}
                    </div>
                    {post.image && (
                        <img
                            src={`https://ipfs.io/ipfs/${post.image}`}
                            alt="User uploaded"
                            className={`ImageContainer${expandedImage === post.postId ? " ExpandedImage" : ""
                                }`}
                            onClick={() =>
                                setExpandedImage(
                                    expandedImage === post.postId ? null : post.postId
                                )
                            }
                            style={{
                                maxWidth: "100%",
                                objectFit: "cover",
                                float: "left",
                                marginRight: "8px",
                                marginBottom: "8px",
                                marginTop: "20px",
                            }}
                        />
                    )}
                    <div className="post-text" style={{ marginTop: "20px" }}>
                        {parsedContent.map((content, index) => (
                            <React.Fragment key={index}>
                                {content}
                                <br />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div className="voting-and-hbar-container">
                    <div className="voting-container">
                        <div className="vote-container">
                            <div className="vote-button-container">
                                <button
                                    className="vote-button"
                                    onClick={() => handleUpvoteClick(post.postId)}
                                >
                                    🔼
                                </button>
                                <span className="vote-count">{post.upvotes}</span>
                            </div>
                            <div className="vote-button-container">
                                <button
                                    className="vote-button"
                                    onClick={() => submitVote(id, post, 'downvote')}
                                >
                                    🔽
                                </button>
                                <span className="vote-count">{post.downvotes}</span>
                            </div>
                        </div>
                        {amountInputVisible[post.postId] && (
                            <div className="amount-input-container">
                                <div className="input-button-wrapper">
                                    <input
                                        className="amount-input"
                                        type="number"
                                        inputMode="decimal"
                                        min="0.1"
                                        step="0.1"
                                        pattern="\d+(\.\d{1,2})?"
                                        placeholder="Upvote donation..."
                                        value={amountToSend[post.postId] || ''}
                                        onChange={(e) =>
                                            setAmountToSend({ ...amountToSend, [post.postId]: e.target.value })
                                        }
                                    />
                                    <button className="send-icon" onClick={() => handleSendUpvote(id, post)}>
                                        <FontAwesomeIcon icon={faCoins} />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="hbar-amount-container">
                        {post.totalHbar} HBAR
                    </div>
                </div>
                {errorMsg[post.postId] && errorMsgVisible[post.postId] && (
                    <div
                        className="error-message"
                        style={{
                            color: 'red',
                            fontWeight: 'bold',
                            opacity: errorMsgVisible[post.postId] ? 1 : 0,
                            transition: 'opacity 2s',
                        }}
                    >
                        {errorMsg[post.postId]}
                    </div>
                )}
                <div>
                    <strong>Replies:</strong>{" "}
                    {getRepliesForPost(post.postId).map((reply, index, array) => (
                        <>
                            <a
                                key={reply.postId}
                                href={`#${reply.postId}`}
                                onClick={() => scrollToElement(reply.postId)}
                            >
                                {reply.postId}{"#"}
                            </a>
                            {index < array.length - 1 && '\u00A0'}
                        </>
                    ))}
                </div>
                <button className="reply-button" onClick={() => handleReplyButtonClick(post.postId)}>
                    Reply
                </button>
            </>
        );
    };

    return (
        <div>
            <Helmet>
                <title>{`/${interestId}/ - ${subject} - 4orum.io`}</title>
                {posts.length > 0 &&
                    <>
                        <meta property="og:title" content={`/${interestId}/ - ${subject} - 4orum.io`} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:type" content="schema:DiscussionForumPosting" />
                        <meta name="description" content={posts[0].comment} />
                        <meta property="og:description" content={posts[0].comment} />
                        <meta property="og:locale" content="en_US" />
                        {posts[0].image &&
                            <meta property="og:image" content={`https://ipfs.io/ipfs/${posts[0].image}`} />
                        }
                    </>
                }
            </Helmet>
            {isLoading ? (
                <LoadingScreen />
            ) : isError ? (
                <div>Error: {error.message}</div>
            ) : (
                <>
                    <div>
                        <h2 className="interest-title">/{interestId}/ - {interestName}</h2>
                        <div className="Thread">
                            {renderFloatingReplyForm()}
                            <div className="TopButtons">
                                <Link to={`/${fromAllPage ? 'all' : interestId}`}>
                                    <button>Index</button>
                                </Link>
                                <button onClick={() => window.scrollTo(0, document.body.scrollHeight)}>
                                    Bottom
                                </button>

                                <Link
                                    to={`/${interestId}/new-thread`}
                                    onClick={(e) => {
                                        if (!accountId) {
                                            e.preventDefault();
                                            setErrorMsgVisible({ ...errorMsgVisible, 'new-thread': true });
                                            handleWalletError('new-thread', 'You must connect your hashpack wallet to create a new thread');
                                        }
                                    }}
                                >
                                    <button>New Thread</button>
                                </Link>
                                <button onClick={() => refetch({ force: true })}>Refresh</button>
                            </div>
                            {errorMsg['new-thread'] && errorMsgVisible['new-thread'] && (
                                <div
                                    className="error"
                                    style={{
                                        color: 'red',
                                        fontWeight: 'bold',
                                        opacity: errorMsgVisible['new-thread'] ? 1 : 0,
                                        transition: 'opacity 2s',
                                    }}
                                >
                                    {errorMsg['new-thread']}
                                </div>
                            )}
                            <div className="Posts" onClick={handleParentIdClick}>
                                {posts.length > 0 && (
                                    <div
                                        key={posts[0].postId}
                                        id={posts[0].postId}
                                        className={getPostClassName(posts[0].userId)}
                                    >
                                        {renderPost(posts[0], 0)}
                                    </div>
                                )}
                                {posts.slice(1).map((post, index) => (
                                    <div
                                        key={post.postId}
                                        id={post.postId}
                                        className={getPostClassName(post.userId)}
                                    >
                                        {renderPost(post, index + 1)}
                                    </div>
                                ))}
                            </div>
                            <div className="BottomButtons">
                                <button onClick={() => window.scrollTo(0, 0)}>Top</button>
                                <Link to={`/${interestId}`}>
                                    <button>Thread Index</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </div>
    );
};

export default Thread;